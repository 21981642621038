import React, { useState, useEffect } from 'react';

const NewGamesAnnouncementPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const url = window.location.href;
    if (url.includes('pbingo') || url.includes('phoenix') || url.includes('local')) {
      setIsOpen(true);
    }
  }, []);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 animate-fadeIn">
      <div className="bg-gradient-to-br from-purple-800 to-indigo-900 p-8 rounded-xl max-w-3xl w-full mx-4 text-center shadow-2xl border-4 border-yellow-400 animate-scaleIn">
        <h2 className="text-yellow-300 text-5xl font-bold mb-6 animate-bounce">እንኳን ደስ አለዎት!</h2>
        <p className="text-white text-xl mb-6 animate-slideInFromLeft">
          ፊንክስ ጌምስ 3 አዳዲስ ጨዋታዎችን ለሚቀጥሉት 5 ሳምንታት በየሳምንቱ የሚለቀቁ update አካል ሆነው ይፋ አድርጓል!
        </p>
        <p className="text-yellow-200 text-2xl font-semibold mb-4 animate-pulse">
          በዚህ ሳምንት የተለቀቁት ጨዋታዎች
        </p>
        <ul className="text-white text-xl list-none p-0 mb-8 animate-slideInFromRight w-50 text-left mx-auto">
          <li className="mb-3 bg-indigo-700 py-2 px-4 rounded-lg shadow-md">Fast Bingo</li>
          <li className="mb-3 bg-indigo-700 py-2 px-4 rounded-lg shadow-md">Super Fast Bingo</li>
          <li className="bg-indigo-700 py-2 px-4 rounded-lg shadow-md">Online + Shop Bingo (x2 አሸናፊ ይሁኑ - 5,000 ብር ድረስ)</li>
        </ul>
        <button 
          onClick={() => setIsOpen(false)} 
          className="bg-yellow-400 text-purple-900 text-2xl font-bold py-3 px-8 rounded-full hover:bg-yellow-300 transition duration-300 ease-in-out transform hover:scale-105 animate-pulse shadow-lg"
        >
          አሁን ይጫወቱ!
        </button>
      </div>
    </div>
  );
};

export default NewGamesAnnouncementPopup;