import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal, Form, Spinner, Table } from 'react-bootstrap';

const AdminTable = () => {
  const API_V1 = process.env.REACT_APP_API;
  const API_V2 = 'https://phoenix-bingo-2.onrender.com/api';
  const [admins, setAdmins] = useState([]);
  const [editingAdminId, setEditingAdminId] = useState(null);
  const [addAmount, setAddAmount] = useState('');
  const [showRegistration, setShowRegistration] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [initialWallet, setInitialWallet] = useState('');
  const [lastFiftyGames, setLastFiftyGames] = useState([]);
  const [showLastFiftyGames, setShowLastFiftyGames] = useState(false);
  const [lastTenGamesUsername, setLastTenGamesUsername] = useState('');
  const [showLast30DaysProfits, setShowLast30DaysProfits] = useState(false);
  const [last30DaysProfits, setLast30DaysProfits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(false);
  const [enteredPassword, setEnteredPassword] = useState('');
  const [registrationApi, setRegistrationApi] = useState(API_V1);
  const [showOldAdmins, setShowOldAdmins] = useState(false);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [showMonthlyProfits, setShowMonthlyProfits] = useState(false);
  const [showWeeklyProfits, setShowWeeklyProfits] = useState(false);
  const [selectedAdminProfits, setSelectedAdminProfits] = useState({ monthly: [], weekly: [] });
  const [selectedAdminUsername, setSelectedAdminUsername] = useState('');
  const [masterKey, setMasterKey] = useState('');
  
  const API = process.env.REACT_APP_API;

  useEffect(() => {
    const checkAuthorization = () => {
      const expirationTime = localStorage.getItem('authExpiration');
      if (expirationTime && new Date().getTime() < parseInt(expirationTime)) {
        setAuthorized(true);
        fetchAdmins();
      } else {
        localStorage.removeItem('authExpiration');
        setAuthorized(false);
        setShowPasswordPrompt(true);
      }
    };

    checkAuthorization();
    const interval = setInterval(checkAuthorization, 60 * 1000); // Check every minute

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (enteredPassword === '2256') {
      const expirationTime = new Date().getTime() + 5 * 60 * 1000; // 5 minutes from now
      localStorage.setItem('authExpiration', expirationTime);
      setAuthorized(true);
      setShowPasswordPrompt(false);
      fetchAdmins();
    } else {
      alert('Invalid password');
    }
  };

  const handleRefresh = () => {
    setIsLoading(true);
    fetchAdmins().finally(() => {
      setIsLoading(false);
    });
  };

  const LoadingOverlay = () => {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
        }}
      >
        <Spinner animation="border" variant="light" />
      </div>
    );
  };

  const [showTransactions, setShowTransactions] = useState(false);
  const [recentTransactions, setRecentTransactions] = useState([]);

  const fetchRecentTransactions = async () => {
    try {
      const response1 = await axios.get(`${API_V1}/admin/transactions`);
      const response2 = await axios.get(`${API_V2}/admin/transactions`);
      const allTransactions = [...response1.data, ...response2.data];
      const filteredTransactions = allTransactions
        .sort((a, b) => new Date(b.date) - new Date(a.date));
      setRecentTransactions(filteredTransactions);
      console.log(filteredTransactions)
    } catch (error) {
      console.error('Error fetching recent transactions:', error);
    }
  };

  const handleShowTransactions = () => {
    fetchRecentTransactions();
    setShowTransactions(true);
  };

  const [lastTenGames, setLastTenGames] = useState([]);

  const fetchLast30DaysProfits = () => {
    setIsLoading(true);
    const today = new Date();
    const last30Days = new Date(today);
    last30Days.setDate(today.getDate() - 30);

    const last30DaysProfits = [];

    for (let date = last30Days; date <= today; date.setDate(date.getDate() + 1)) {
      const dateString = date.toISOString().slice(0, 10);
      const dailyProfit = admins
      .filter((admin) => admin.username !== 'testv1')
      .reduce((total, admin) => {
        const dailyStat = admin.statsByPeriod.find((stat) => stat.date.startsWith(dateString));
        return total + (dailyStat ? dailyStat.profit : 0);
      }, 0);
      last30DaysProfits.push({ date: dateString, profit: Math.round(dailyProfit * 0.175) });
    }

    setLast30DaysProfits(last30DaysProfits.reverse());
    setShowLast30DaysProfits(true);
    setIsLoading(false)
  };

  const fetchLastTenGames = async (adminId, adminUsername) => {
    setIsLoading(true);
    try {
      let gamesFromV1 = [];
      let gamesFromV2 = [];
  
      if (adminUsername !== '0935880205-16') {
        try {
          console.log('v1 hit')
          const responseV1 = await axios.get(`${API_V1}/admin/${adminId}/last-games`);
          gamesFromV1 = responseV1.data;
        } catch (error) {
          console.log('API_V1 failed:', error);
        }
      }
  
      try {
        console.log('v2 hit')
        const responseV2 = await axios.get(`${API_V2}/admin/${adminId}/last-games`);
        gamesFromV2 = responseV2.data;
      } catch (error) {
        console.log('API_V2 failed:', error);
      }
      const allGames = [...gamesFromV1, ...gamesFromV2];
  
      const sortedGames = allGames.sort((a, b) => new Date(b.date) - new Date(a.date));
      const lastTenGames = sortedGames.slice(0, 10);
  
      setLastTenGames(lastTenGames);
      setLastTenGamesUsername(adminUsername);
    } catch (error) {
      console.error('Error fetching last 10 games:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLastFiftyGames = async () => {
    try {
      setIsLoading(true);
      
      // Fetch from both APIs concurrently
      const [responseAPI1, responseAPI2] = await Promise.all([
        axios.get(`${API_V1}/admin/last-games`).catch(error => {
          console.error('Error fetching from API1:', error);
          return { data: [] };
        }),
        axios.get(`${API_V2}/admin/last-games`).catch(error => {
          console.error('Error fetching from API2:', error);
          return { data: [] };
        })
      ]);
  
      console.log('API1 response:', responseAPI1.data.length, 'games');
      console.log('API2 response:', responseAPI2.data.length, 'games');
  
      // Combine the results into a single array
      const allGames = [...responseAPI1.data, ...responseAPI2.data];
      console.log('Combined games:', allGames.length);
  
      // Sort all games together by createdAt in descending order (latest first)
      const sortedGames = allGames.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
  
      console.log('Sorted games (first 5):', sortedGames.slice(0, 5).map(game => ({
        id: game._id,
        createdAt: game.createdAt,
        bettingAmount: game.bettingAmount,
        numberOfPlayers: game.numberOfPlayers
      })));
  
      // Take the last 200 games after sorting
      const lastTwoHundredGames = sortedGames.slice(0, 200);
  
      console.log('Final games count:', lastTwoHundredGames.length);
  
      setLastFiftyGames(lastTwoHundredGames);
      setShowLastFiftyGames(true);
    } catch (error) {
      console.error('Error in fetchLastFiftyGames:', error);
    } finally {
      setIsLoading(false);
    }
  };
    const handleRegistration = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(`${registrationApi}/admin/signup/protected/naan`, { username, password, initialWallet });
      alert('User registered successfully');
      setShowRegistration(false);
      setUsername('');
      setPassword('');
      setInitialWallet('');
      fetchAdmins();
    } catch (error) {
      console.error('Error registering user:', error);
      alert('Failed to register user');
    } finally {
      setIsLoading(false);
    }
  };

  const getYesterdaysProfit = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayString = yesterday.toISOString().slice(0, 10);

    const yesterdayStats = admins
      .filter((admin) => admin.username !== 'testv1')
      .flatMap((admin) =>
        admin.statsByPeriod.filter((stat) => stat.date.startsWith(yesterdayString))
      );

    const totalProfit = yesterdayStats.reduce((sum, stat) => sum + stat.profit, 0);
    return totalProfit * 0.175;
  };


  const naturalSort = (a, b) => {
    return a.username.localeCompare(b.username, undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  };

  const handleShowMonthlyProfits = (admin) => {
    setSelectedAdminProfits({ monthly: admin.last12MonthsProfits, weekly: admin.last12WeeksProfits });
    setSelectedAdminUsername(admin.username);
    setShowMonthlyProfits(true);
  };

  const handleShowWeeklyProfits = (admin) => {
    setSelectedAdminProfits({ monthly: admin.last12MonthsProfits, weekly: admin.last12WeeksProfits });
    setSelectedAdminUsername(admin.username);
    setShowWeeklyProfits(true);
  };

  const getMonthName = (monthIndex) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[monthIndex];
  };

  const getWeekDates = (weekIndex) => {
    const today = new Date();
    const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - (7 * weekIndex));
    const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6);
    return `${startOfWeek.toLocaleDateString()} - ${endOfWeek.toLocaleDateString()}`;
  };

  const fetchAdmins = async () => {
    try {
      const response1 = await axios.get(`${API_V1}/admin/naan/all/protected`);
      console.log(response1)
      const response2 = await axios.get(`${API_V2}/admin/naan/all/protected`);
      const allAdmins = [
        ...response1.data.map(admin => ({ ...admin, api: API_V1 })),
        ...response2.data.map(admin => ({ ...admin, api: API_V2 }))
      ];
      const filteredAdmins = allAdmins.filter((admin) =>
        (!admin.username.endsWith('/')));
      const sortedAdmins = filteredAdmins.sort(naturalSort);
      setAdmins(sortedAdmins);
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  };

  const handleEditWallet = (adminId) => {
    setEditingAdminId(adminId);
    setAddAmount('');
  };

  const handleAddAmountChange = (event) => {
    setAddAmount(event.target.value);
  };


  const addToWallet = async (adminId, api) => {
    try {
      const currentAdmin = admins.find((admin) => admin._id === adminId);
      const updatedWallet = currentAdmin.wallet + parseFloat(addAmount);
      await axios.put(`${api}/admin/${adminId}/wallet`, { wallet: updatedWallet, amount: addAmount });
      await fetchAdmins();
      setEditingAdminId(null);
      setAddAmount('');
    } catch (error) {
      console.error('Error updating admin wallet:', error);
    }
  };



  const getTodaysProfit = (admin) => {
    const today = new Date().toISOString().slice(0, 10);
    const todayStat = admin.statsByPeriod.find((stat) => stat.date.startsWith(today));
    return todayStat ? todayStat.profit : 0;
  };

  const getThisWeekAverage = (admin) => {
    const today = new Date();
    const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));

    const thisWeekStats = admin.statsByPeriod.filter(
      (stat) => new Date(stat.date) >= startOfWeek && new Date(stat.date) <= endOfWeek
    );

    const totalProfit = thisWeekStats.reduce((sum, stat) => sum + stat.profit, 0);
    const averageProfit = totalProfit / thisWeekStats.length;

    return isNaN(averageProfit) ? 0 : averageProfit.toFixed(2);
  };

  const getThisWeekProfit = (admin) => {
    const today = new Date();
    const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));

    const thisWeekStats = admin.statsByPeriod.filter(
      (stat) => new Date(stat.date) >= startOfWeek && new Date(stat.date) <= endOfWeek
    );

    const totalProfit = thisWeekStats.reduce((sum, stat) => sum + stat.profit, 0);
    return totalProfit;
  };


  const getMaxProfit = (admin) => {
    const maxProfit = Math.max(...admin.statsByPeriod.map((stat) => stat.profit));
    return isFinite(maxProfit) ? maxProfit : 0;
  };

  const getTodaysTotalProfit = () => {
    return admins
      .filter((admin) => admin.username !== 'testv1')
      .reduce((total, admin) => total + getTodaysProfit(admin), 0);
  };

  const getThisWeekTotalProfit = () => {
    return admins
      .filter((admin) => admin.username !== 'testv1')
      .reduce((total, admin) => total + getThisWeekProfit(admin), 0);
  };

  const getMonthlyProfit = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const monthlyStats = admins
      .filter((admin) => admin.username !== 'testv1')
      .flatMap((admin) =>
        admin.statsByPeriod.filter((stat) => {
          const statDate = new Date(stat.date);
          return statDate.getMonth() === currentMonth && statDate.getFullYear() === currentYear;
        })
      );

    const totalProfit = monthlyStats.reduce((sum, stat) => sum + stat.profit, 0);
    return totalProfit;
  };

  const [blockingAdminId, setBlockingAdminId] = useState(null);

  const handleBlockUnblock = async (adminId, action) => {
    try {
      setIsLoading(true);
      let response;
  
      try {
        // First, try API_V1
        response = await axios.post(`${API_V1}/admin/${adminId}/${action}`);
      } catch (error) {
        console.log('API_V1 failed, trying API_V2');
        // If API_V1 fails, try API_V2
        response = await axios.post(`${API_V2}/admin/${adminId}/${action}`);
      }
      fetchAdmins();
    } catch (error) {
      console.error(`Error ${action}ing admin:`, error);
      alert(`Failed to ${action} admin`);
    } finally {
      setIsLoading(false);
    }
  };

  const getTotalProfitToDate = () => {
    const totalProfit = admins
      .filter((admin) => admin.username !== 'testv1')
      .reduce((sum, admin) => sum + admin.totalProfit, 0);
    return totalProfit;
  };

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const sortAdmins = (admins, key, direction) => {
    return admins.sort((a, b) => {
      let valueA, valueB;

      if (key === 'lastLoginTime') {
        valueA = a.lastLoginTime ? new Date(a.lastLoginTime) : 0;
        valueB = b.lastLoginTime ? new Date(b.lastLoginTime) : 0;
      } else if (key === 'todaysProfit') {
        valueA = getTodaysProfit(a);
        valueB = getTodaysProfit(b);
      } else if (key === 'thisWeekAverage') {
        valueA = parseFloat(getThisWeekAverage(a));
        valueB = parseFloat(getThisWeekAverage(b));
      } else if (key === 'maxProfit') {
        valueA = getMaxProfit(a);
        valueB = getMaxProfit(b);
      } else if (key === 'gamesToday') {
        valueA = a.gamesToday || 0;
        valueB = b.gamesToday || 0;
      } else {
        valueA = a[key];
        valueB = b[key];
      }

      if (valueA < valueB) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (valueA > valueB) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  const sortedAdmins = sortConfig.key
    ? sortAdmins(admins, sortConfig.key, sortConfig.direction)
    : admins;

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getArrowDirection = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      try {
        await axios.post(`${API}/admin/change-password`, {
          adminId: selectedAdminId,
          newPassword,
          masterKey
        });
      } catch(error){
        console.log('API_V1 failed, trying API_V2');
        await axios.post(`${API_V2}/admin/change-password`, {
          adminId: selectedAdminId,
          newPassword,
          masterKey
        });
      }
      alert('Password changed successfully');
      setShowPasswordChange(false);
      setNewPassword('');
      setMasterKey('');
    } catch (error) {
      console.error('Error changing password:', error);
      alert('Failed to change password');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      {isLoading && <LoadingOverlay />}
      {showPasswordPrompt && (
        <Modal show={showPasswordPrompt} onHide={() => setShowPasswordPrompt(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Password Required</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handlePasswordSubmit}>
              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={enteredPassword}
                  onChange={(e) => setEnteredPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}
      {authorized && (
      <div>
      <div className='d-flex'>
        <h2 className="my-2">Admin Dashboard</h2>
        <Button variant="primary" onClick={handleRefresh} className="m-3" disabled={isLoading}>
          {isLoading ? 'Refreshing...' : 'Refresh Data'}
        </Button>
        <Button variant="danger" onClick={() => setShowRegistration(true)} className="m-3" style={{maxHeight: '65px', marginLeft: '10%' }}>
          Register User
        </Button>
        <Button variant="info" onClick={fetchLastFiftyGames} className="m-3">
          Last 50 Games
        </Button>
        <Button variant="dark" onClick={fetchLast30DaysProfits} className="m-3">
          Last 30 Days Profits
        </Button>
        <Button
          onClick={handleShowTransactions} variant="secondary" className="m-3"
        >
          Recent Transactions
        </Button>
      </div>

      <Modal show={showPasswordChange} onHide={() => setShowPasswordChange(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handlePasswordChange}>
            <Form.Group controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="masterKey">
              <Form.Label>Master Key</Form.Label>
              <Form.Control
                type="password"
                value={masterKey}
                onChange={(e) => setMasterKey(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Change Password
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showTransactions} onHide={() => setShowTransactions(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Recent Transactions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>To Admin</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {recentTransactions.map((transaction) => (
                  <tr key={transaction._id}>
                    <td>{new Date(transaction.date).toLocaleString()}</td>
                    <td>{transaction.toAdmin?.username}</td>
                    <td>{transaction.amount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>

      <Modal show={showLast30DaysProfits} onHide={() => setShowLast30DaysProfits(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Profits of Last 30 Days</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>Profit</th>
              </tr>
            </thead>
            <tbody>
              {last30DaysProfits.map((entry) => (
                <tr key={entry.date}>
                  <td>{entry.date}</td>
                  <td>{entry.profit}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>

      <Modal show={showLastFiftyGames} onHide={() => setShowLastFiftyGames(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Last 50 Games</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>Admin</th>
                <th>Betting Amount</th>
                <th>Number of Players</th>
                <th>Winning Amount</th>
                <th>Profit</th>
                <th> Status</th>
                <th> Type</th>
              </tr>
            </thead>
            <tbody>
              {lastFiftyGames.sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return dateB - dateA;
              })
              .map((game) => (
                <tr key={game._id}>
                  <td>{new Date(game.createdAt).toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })} {new Date(game.createdAt).toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                  })}</td>
                  <td>{game.admin? game.admin.username : ''}</td>

                  <td>{game.bettingAmount}</td>
                  <td>{game.numberOfPlayers}</td>
                  <td>{game.payoutToWinner}</td>
                  <td>{game.bettingAmount * game.numberOfPlayers * 0.2}</td>
                  <td>{game.status}</td>
                  <td>{game.gameType}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>

      <Modal show={showRegistration} onHide={() => setShowRegistration(false)}>
        <Modal.Header closeButton>
          <Modal.Title>User Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleRegistration}>
            <Form.Group controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            </Form.Group>
            <Form.Group controlId="api">
              <Form.Label>API Version</Form.Label>
              <Form.Control as="select" onChange={(e) => setRegistrationApi(e.target.value)} value={registrationApi}>
                <option value={API_V1}>Cartella 1</option>
                <option value={API_V2}>Cartella 2</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Register
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <div className='m-2'>
        <button className="btn btn-warning">Today's Profit: {getTodaysTotalProfit() * 0.175}</button>
        <button className="btn btn-success mx-2">Yesterday's Profit: {Math.round(getYesterdaysProfit())}</button>
        <button className="btn btn-success mx-2">This Week's Total Profit: {Math.round(getThisWeekTotalProfit() * 0.175)}</button>
        <button className="btn btn-success">This month's Profit: {Math.round(getMonthlyProfit() * 0.175)}</button>
        <button className="btn btn-success mx-2">Total Profit to Date: {Math.round(getTotalProfitToDate() * 0.175)}</button>
      </div>
      <div className="m-2 d-flex">
        <button className="btn btn-dark">Today's Total Revenue: {Math.round(getTodaysTotalProfit())}</button>
        <button className="btn btn-dark mx-2">This Week's Total Revenue: {Math.round(getThisWeekTotalProfit())}</button>
        <button className="btn btn-dark">Monthly Revenue: {Math.round(getMonthlyProfit())}</button>
        <button className="btn btn-dark mx-2">Total Revenue to Date: {Math.round(getTotalProfitToDate())}</button>
      </div>

      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th onClick={() => requestSort('username')}>
              Username {getArrowDirection('username')}
            </th>
            <th onClick={() => requestSort('lastLoginTime')}>
              Last Login {getArrowDirection('lastLoginTime')}
            </th>
            <th onClick={() => requestSort('totalProfit')}>
              Total Profit {getArrowDirection('totalProfit')}
            </th>
            <th onClick={() => requestSort('wallet')}>
              Wallet {getArrowDirection('wallet')}
            </th>
            <th>Stats by Period</th>
            <th onClick={() => requestSort('todaysProfit')}>
              Today's Profit {getArrowDirection('todaysProfit')}
            </th>
            <th onClick={() => requestSort('thisWeekAverage')}>
              This Week's Average {getArrowDirection('thisWeekAverage')}
            </th>
            <th>This Week's Profit</th>
            <th>This Month's Profit</th>
            <th>Avg. Monthly Profit</th>
            <th>Avg. All-Time Profit</th>
            <th onClick={() => requestSort('maxProfit')}>
              Max Profit to Date {getArrowDirection('maxProfit')}
            </th>
            <th>Actions</th>
            <th>Last Games</th>
            <th>Block</th>
            <th>Change password</th>
            <th>Monthly Profits</th>
            <th>Weekly Profits</th>
          </tr>
        </thead>
        <tbody>
        {admins.filter(admin => admin.username !== 'testv1' && admin.username !== 'testv2' && !admin.username.endsWith('-old')).map((admin) => (
              <tr key={`${admin._id}-${admin.api}`}>
              <td>{admin.username}</td>
              <td>
                {admin.lastLoginTime ? (
                  <>
                    {new Date(admin.lastLoginTime).toLocaleDateString('en-US', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}{' '}
                    {new Date(admin.lastLoginTime).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                    })}
                  </>
                ) : (
                  ''
                )}
              </td>

              <td>{admin.totalProfit}</td>
              <td>{admin.wallet}</td>
              <td>
                <details>
                  <summary className="btn btn-sm btn-info">View Stats</summary>
                  <table className="table table-bordered mt-2">
                    <thead>
                      <tr>
                        <th>Period</th>
                        <th>Profit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {admin.statsByPeriod.map((stat, index) => (
                        <tr key={index}>
                          <td>{new Date(stat.date).toLocaleDateString()}</td>
                          <td>{stat.profit}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </details>
              </td>
              <td>{getTodaysProfit(admin)}</td>
              <td>{Math.round(getThisWeekAverage(admin))}</td>
              <td>{admin.weekProfit?.toFixed(0)}</td>
              <td>{admin.monthProfit?.toFixed(0)}</td>
              <td>{admin.avgMonthProfit?.toFixed(0)}</td>
              <td>{admin.avgAllTimeProfit?.toFixed(0)}</td>
              <td>{Math.round(getMaxProfit(admin))}</td>
              <td>
                {editingAdminId === admin._id ? (
                  <div className="input-group mt-1">
                    <input
                      type="number"
                      value={addAmount}
                      onChange={handleAddAmountChange}
                      className="form-control"
                      placeholder="Add amount"
                    />
                    <button onClick={() => addToWallet(admin._id, admin.api)} className="btn btn-primary">
                      Add
                    </button>
                    <button onClick={() => setEditingAdminId(null)} className="btn btn-secondary">
                      Cancel
                    </button>
                  </div>
                ) : (
                  <button onClick={() => handleEditWallet(admin._id)} className="btn btn-warning btn-sm">
                    Edit
                  </button>
                )}
              </td>
              <td className="whitespace-nowrap text-md font-small">
                <Button
                  onClick={() => fetchLastTenGames(admin._id, admin.username, admin.api)}
                  className="btn btn-info"
                  size='sm'
                >
                  Games
                </Button>
              </td>
              <td>
                {admin.isBlocked ? (
                  <Button 
                    variant="success" 
                    size="sm" 
                    onClick={() => handleBlockUnblock(admin._id, 'unblock', admin.api)}
                    disabled={blockingAdminId === admin._id}
                  >
                    Unblock
                  </Button>
                ) : (
                  <Button 
                    variant="danger" 
                    size="sm" 
                    onClick={() => handleBlockUnblock(admin._id, 'block', admin.api)}
                    disabled={blockingAdminId === admin._id}
                  >
                    Block
                  </Button>
                )}
              </td>
              <td>
              <Button 
                variant="success" 
                size="sm" 
                onClick={() => {
                  setSelectedAdminId(admin._id);
                  setShowPasswordChange(true);
                }}
                disabled={blockingAdminId === admin._id}
              >
                Password
              </Button>
              </td>
              <td>
                <Button 
                  variant="info" 
                  size="sm" 
                  onClick={() => handleShowMonthlyProfits(admin)}
                >
                  View Monthly Profits
                </Button>
              </td>
              <td>
                <Button 
                  variant="info" 
                  size="sm" 
                  onClick={() => handleShowWeeklyProfits(admin)}
                >
                  View Weekly Profits
                </Button>
              </td>
              
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        show={lastTenGames.length > 0}
        onHide={() => setLastTenGames([])}
        username={lastTenGames.length > 0 ? lastTenGames[0].admin.username : ''}
      >
        <Modal.Header closeButton>
          <Modal.Title>Last 10 Games - {lastTenGamesUsername}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>Betting Amount</th>
                <th>Number of Players</th>
                <th>Winning Amount</th>
                <th>Profit</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {lastTenGames.map((game) => (
                <tr key={game._id}>
                  <td>
                    {new Date(game.createdAt).toLocaleDateString('en-US', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}{' '}
                    {new Date(game.createdAt).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                    })}
                  </td>
                  <td>{game.bettingAmount}</td>
                  <td>{game.numberOfPlayers}</td>
                  <td>{game.payoutToWinner}</td>
                  <td>{game.bettingAmount * game.numberOfPlayers * 0.2}</td>
                  <td>{game.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>

      <Modal show={showMonthlyProfits} onHide={() => setShowMonthlyProfits(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Monthly Profits - {selectedAdminUsername}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Month</th>
                <th>Profit</th>
              </tr>
            </thead>
            <tbody>
              {selectedAdminProfits?.monthly.map((profit, index) => {
                const date = new Date();
                date.setMonth(date.getMonth() - (11 - index));
                return (
                  <tr key={index}>
                    <td>{getMonthName(date.getMonth())} {date.getFullYear()}</td>
                    <td>{profit?.toFixed(0)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      {/* Weekly Profits Modal */}
      <Modal show={showWeeklyProfits} onHide={() => setShowWeeklyProfits(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Weekly Profits - {selectedAdminUsername}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Week</th>
                <th>Profit</th>
              </tr>
            </thead>
            <tbody>
              {selectedAdminProfits.weekly.map((profit, index) => (
                <tr key={index}>
                  <td>Week {12 - index}: {getWeekDates(11 - index)}</td>
                  <td>{profit?.toFixed(0)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      </div>
      )}
    </div>
  );
};

export default AdminTable;
