import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import { FaGamepad } from 'react-icons/fa';
import CryptoJS from 'crypto-js'

function AdminGames() {
  const [games, setGames] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalGames, setTotalGames] = useState(0);
  const getApiUrl = () => {
    const hashedApiVersion = localStorage.getItem('apiVersion');
    if (hashedApiVersion) {
      const version2Hash = CryptoJS.SHA256('2').toString();
      if (hashedApiVersion === version2Hash) {
        return 'https://phoenix-bingo-2.onrender.com/api';
      }
    }
    return process.env.REACT_APP_API;
  };

  const API = getApiUrl();

  const [stats, setStats] = useState({
    statsByPeriod: [],
    totalProfit: 0,
    totalBetAmount: 0,
    totalGames: 0,
    wallet: 0
  });
  
  useEffect(() => {
    const fetchStats = async () => {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API}/admin/stats`, {
        headers: { Authorization: `Bearer ${token}` }  
      });
      const data = await response.json();
      setStats(data);
    };

    fetchStats();
  }, [API]);

  useEffect(() => {
    fetchGames();
  }, [page]); // Fetch games when page changes

  const fetchGames = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await fetch(`${API}/admin/myGames?page=${page}`, {
        headers: { Authorization: `Bearer ${token}` }  
      });
      
      const data = await response.json();
      if (page === 1) {
        setGames(data.games);
      } else {
        setGames(prevGames => [...prevGames, ...data.games]);
      }
      setTotalPages(data.totalPages);
      setTotalGames(data.totalGames);
      setLoading(false);

      console.log('Fetched data:', data);
      console.log('Current page:', data.currentPage);
      console.log('Total pages:', data.totalPages);
    } catch(error) {
      console.log(error);
      setLoading(false);
    }
  }

  const loadMoreGames = () => {
    if (page < totalPages) {
      setPage(prevPage => prevPage + 1);
    }
  }

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const year = date.getFullYear();
    const month = months[date.getMonth()]; 
    const day = date.getDate();
    const dayOfWeek = days[date.getDay()];
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  
    return `${dayOfWeek}, ${month} ${day}, ${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`; 
  }
  
  return (
    <div className="my-3 m-4 p-3">
      <h2 className='text-white'>Statistics</h2>
      <div className='d-flex'>
        <div className="d-flex align-items-center p-3" style={{minHeight: '75px', backgroundColor: '#000', marginRight: 100, borderRadius: '16px'}}>
          <FaGamepad className="text-primary mr-2 fs-1" />
          <span className="text-white fs-5">Total Games: {stats.totalGames}</span>
        </div>
        <div className="d-flex align-items-center p-3" style={{minHeight: '75px', backgroundColor: '#000', marginRight: 100, borderRadius: '16px'}}>
          <FaGamepad className="text-primary mr-2 fs-1" />
          <span className="text-white fs-5">Wallet: {stats.wallet} Birr</span>
        </div>
      </div>
      <h4 className='text-white mt-5'>Recent Games</h4>
      
      <Table bordered hover variant="dark">
        <thead>
          <tr>
            <th style={{backgroundColor: 'black'}}>Date</th> 
            <th style={{backgroundColor: 'black'}}>Bet Amount</th>
            <th style={{backgroundColor: 'black'}}>Players</th>
            <th style={{backgroundColor: 'black'}}>Status</th>
            <th style={{backgroundColor: 'black'}}>Total Bet Amount</th>
            <th style={{backgroundColor: 'black'}}>Profit</th>
          </tr>
        </thead>

        <tbody>
          {games.map(game => (
            <tr key={game.id}>  
              <td>{formatDate(game.date)}</td>
              <td>{game.bettingAmount} Birr</td>
              <td>{game.players}</td>
              <td>{game.status}</td>
              <td>{game.bettingAmount * game.players} Birr</td>
              <td>{game.bettingAmount * (game.players + (game.houseCardCount || 0))* 0.2} Birr</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between align-items-center">
        <p className="mb-0">
          Showing {games.length} out of {totalGames} games
        </p>
        
        <div>
          {page < totalPages && (
            <Button 
              className="btn btn-primary mr-2" 
              onClick={loadMoreGames} 
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Load More'}
            </Button>
          )}
          <Button className="btn btn-secondary">
            Export CSV
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AdminGames;